import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { Router } from 'express';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  siteStatusData: string[] = ['On-going','Started','Completed','Stopped',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  expensesTypeForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  _id:any;
  expensesTypeData:any[]=[];

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {

    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this._id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this._id);
    }

    this.expensesTypeForm= this.formBuilder.group({
      expensesTypeName: ['', Validators.required],
     
      
    });
 
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.expensesTypeForm.patchValue({
      expensesTypeName:data.expensesTypeName
    })
  }

  goBack() {
    this.router.navigate(['/dashboard/expensesType']);
  }

  get f() {
    return this.expensesTypeForm.controls;
  }

  onSubmit(data: any) {
    if (this.expensesTypeForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }
    data.postOn=moment().format()
    this.data.update(data,this._id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/expensesType'])
    })
  }
  onCancel(){
    this.expensesTypeForm.reset()
  }
}
