
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New ExpensesType</h2>
    <form [formGroup]="expensesTypeForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="expensesTypeName">Expenses Type Name<span class="requried-field">*</span></label>
          <input
            type="text"
            id="expensesTypeName"
            placeholder="Enter expensesTypeName"
            class="form-control"
            formControlName="expensesTypeName"
            [ngClass]="{ 'is-invalid': submitted && f['expensesTypeName'].errors }"
          />
          <div *ngIf="submitted && f['expensesTypeName'].errors" class="invalid-feedback">
            <div *ngIf="f['expensesTypeName'].errors['required']">Expenses Type is required</div>
          </div>
        </div>
        </div>
       

      
  
      <div class="formbutton">
        <button type="submit" class="btn btn-primary" (click)="onSubmit(expensesTypeForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  