import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, mapTo, throwError, } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    expensesTypeUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/expensesType.json`;
    expensesTypeIdUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/expensesType`;

    constructor(private http:HttpClient) {}

    create(data: any) {
        return this.http.post(`${this.expensesTypeUrl}`, data).pipe(
           mapTo(true),
          catchError(error => {
            return error;
          })
        );
      }
      get(): Observable<any> {
        return this.http.get(this.expensesTypeUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      update(data:any,id: any): Observable<any | boolean> {
        return this.http.put(`${this.expensesTypeIdUrl}/${id}.json`,data).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      getById(id: any): Observable<any | boolean> {
        return this.http.get(`${this.expensesTypeIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      deleteById(id: any): Observable<any | boolean> {
        return this.http.delete(`${this.expensesTypeIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
}
